<template>
  <Default>
    <div class="usuarios">
    <banner
      :fondo="newAssignment"
      titulo="Registrar asignación"
      descripcion=""
    ></banner>

    <section class="main__content">
      <div class="card">
        <div class="card-header">
          <button
            type="button"
            class="btn btn-outline-danger btn-sm"
            @click="$router.go(-1)"
          >
            Atrás
          </button>
        </div>

        <div class="card-body">
          <form @submit.prevent="crear()">
            <div class="row justify-content-center">
              <div class="col-12 px-3 px-md-0 col-md-10">
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="codigoTicket">Código de ticket</label>
                      <input
                        type="text"
                        id="codigoTicket"
                        class="form-control"
                        required
                        v-model="codigoTicket"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="localId">Local a inspeccionar</label>
                      <select
                        id="localId"
                        class="form-control"
                        v-model="localId"
                      >
                        <option
                          v-for="(item, index) in locales"
                          :key="index"
                          :value="item.value"
                          >{{ item.text }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label for="descripcionServicio"
                        >Descripción de servicio</label
                      >
                      <textarea
                        id="descripcionServicio"
                        class="form-control"
                        required
                        v-model="descripcionServicio"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="supervisorId">Supervisor</label>
                      <select
                        id="supervisorId"
                        class="form-control"
                        v-model="supervisorId"
                      >
                        <option
                          v-for="(item, index) in supervisores"
                          :key="index"
                          :value="item.value"
                          >{{ item.text }}</option
                        >
                      </select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="contratistaId">Contratista</label>
                      <select
                        id="contratistaId"
                        class="form-control"
                        v-model="contratistaId"
                      >
                        <option
                          v-for="(item, index) in contratistas"
                          :key="index"
                          :value="item.value"
                          >{{ item.text }}</option
                        >
                      </select>
                    </div>
                  </div>


                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="finanzasId">Finanzas</label>
                      <select
                        id="finanzasId"
                        class="form-control"
                        v-model="finanzasId"
                      >
                        <option
                          v-for="(item, index) in finanzas"
                          :key="index"
                          :value="item.value"
                          >{{ item.text }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group text-right">
                  <button
                    type="submit"
                    class="btn btn-primary px-4"
                    :disabled="loading || !validar"
                  >
                    <template v-if="loading">
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Cargando...</span>

                      <span class="d-inline-block ml-2">Cargando</span>
                    </template>

                    <span v-else>Registrar Asignación</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>

    <b-modal
      id="modal-asignacion-existente"
      hide-footer
      centered
      title="Asignación existente"
    >
      <p>
        Ya existe una asignación creada con el código
        <span class="font-weight-bold">{{ codigoTicket }}</span>
      </p>

      <!-- <div class="text-right">
        <router-link
          class="btn btn-success"
          :to="{ name: 'asignaciones-id', params: { id: codigoTicket } }"
          >Ver detalles</router-link
        >
      </div> -->
    </b-modal>
  </div>
  </Default>
</template>

<script>
import Default from "@/layouts/default.vue";

import GetAllLocales from "@/apollo/queries/locales/GetAllLocales.gql";
import GetAllUsers from "@/apollo/queries/usuarios/GetAllUsers.gql";

import CreateAsignacion from "@/apollo/mutations/asignaciones/CreateAsignacion.gql";

import Banner from "@/components/global/Banner";
import newAssignment from "@/assets/banner/newAssignment.jpg";

export default {
  data() {
    return {
      numberPaginate: 100,
      estado: 1,
      codigoTicket: "",
      descripcionServicio: null,
      supervisores: [],
      contratistas: [],
      finanzas: [],
      supervisorId: null,
      contratistaId: null,
      finanzasId: null,
      locales: [],
      localId: null,
      loading: false,
      newAssignment: newAssignment,
    };
  },
  components: {
    Banner, Default
  },
  mounted() {
    this.getLocales().then(() => {
      this.getUsuariosOrganizados();
    });
  },
  methods: {
    getLocales() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAllLocales,
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            let datosOrganizados = response.data.GetAllLocales.map((item) => {
              return {
                value: item.localId,
                text: item.nomLocal,
              };
            });

            let datosAMostrar = [
              {
                value: null,
                text: "Seleccione",
              },
            ];

            this.locales = datosAMostrar.concat(datosOrganizados);

            resolve();
          });
      });
    },
    getUsuariosOrganizados() {
      let valorSupervisor = 2,
        valorContratista = 3,
        valorFinanzas = 4;

      this.getUsers(valorSupervisor).then((data) => {this.supervisores = data});

      this.getUsers(valorContratista).then(
        (data) => {this.contratistas = data; }
        
      );

      this.getUsers(valorFinanzas).then((data) => {this.finanzas = data; })
    },
    getUsers(tipo) {
      return new Promise((resolve) => {
        this.query = null;

        this.$apollo
          .query({
            query: GetAllUsers,
            variables: {
              tipoUsuario: parseInt(tipo),
              estado: this.estado,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            let datosOrganizados = response.data.GetAllUsers.map((item) => {
              return {
                value: item.userId,
                text: `${item.nombres} ${item.apellidos}`,
              };
            });

            let datosAMostrar = [
              {
                value: null,
                text: "Seleccione",
              },
            ];

            datosAMostrar = datosAMostrar.concat(datosOrganizados);

            resolve(datosAMostrar);
          });
      });
    },
    crear() {
      this.loading = true;

      let {
        codigoTicket,
        descripcionServicio,
        supervisorId,
        contratistaId,
        finanzasId,
        localId,
      } = this;

      let input = {
        estado: 1,
        codigoTicket,
        descripcionServicio,
        supervisorId,
        contratistaId,
        finanzasId,
        localId,
      };

      this.$apollo
        .mutate({
          mutation: CreateAsignacion,
          variables: {
            input,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            let codigoError = response.errors[0].debugMessage;

            if (codigoError === "CODIGO_REPETIDO") {
              this.$bvModal.show("modal-asignacion-existente");
            }
          }

          this.loading = false;

          let { codigoTicket } = response.data.CreateAsignacion;

          this.$toast.success(`Asignación #${codigoTicket} registrada`, {
            // override the global option
            position: "top-right",
          });

          this.$router.push({ name: "asignaciones" });
        });
    },
  },
  computed: {
    validar() {
      let {
        localId,
        codigoTicket,
        descripcionServicio,
        supervisorId,
        contratistaId,
        finanzasId,
      } = this;

      let status = false;

      if (
        codigoTicket &&
        descripcionServicio &&
        supervisorId &&
        contratistaId &&
        finanzasId &&
        localId
      ) {
        status = true;
      }

      return status;
    },
  },
};
</script>
